
import { Link } from "react-router-dom";
import React  from 'react'
import moment from 'moment'
import { ConvertToCloudFrontUrl } from "../../../../utils/ConvertToCloudFrontUrl";
export default React.memo(function VideoCard(props) {
    const thumbnail = ConvertToCloudFrontUrl(props.thumbnail)
    const avatarUser = ConvertToCloudFrontUrl(props.avatarUser)
    return (
        <div 
            className={ 
                props.nextVideo 
                ? 
                "col-12 me-auto" 
                :
                "col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 me-auto"
            }
        >
            <div className="videoCard m-1 p-1">
                <Link to = {"/watching/" + props.videoId}>
                    <img className="videoCard_thumbnail" alt={'Ảnh thu nhỏ của video '+props.title} src={thumbnail} />
                </Link>
                <div className="videoCard_info d-flex ">
                    {
                        avatarUser &&
                        <Link to = {"/user/"+ props.idUser}  >
                            <img className="img-video_avatar rounded-circle" alt={`Avatar of user ${props.idUser}`}  src={avatarUser} data-bs-dismiss="modal"/>
                        </Link>
                    }
                    <div className="videoCard_info_text">
                        <Link className="nameUser" to = {"/watching/" + props.videoId} >
                            <b className="Title_video" data-bs-dismiss="modal">
                                {props.title}
                            </b>
                        </Link>
                        {
                            !props.publishVideoManager   && 
                            <Link to = {"/user/"+ props.idUser} className="nameUser">
                                <p className="mt-1 mb-1">{props.username}</p>
                            </Link>
                        }
                        {
                                   props.tags && props.tags.length >=1 && 
                                    <b className=" tags_video">{props.tags}</b>
                                }
                            <div className="d-flex justify-content-between">
                                
                                {
                                    props.publishVideoManager && 
                                        <div className="btn-group dropup">
                                            <button type="button" title="Chỉnh sửa video" className="btn rounded ps-1 pe-1" data-bs-toggle="dropdown" aria-expanded="true">
                                                <i className="fa fa-wrench" aria-hidden="true"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <button 
                                                            onClick={()=>props.isShowFormDeleteVideo()}
                                                            className="btn btn-light w-100 " data-bs-toggle="modal" type="button" 
                                                            data-bs-target={props.videoIdInFormDelete
                                                                ?"#deleteVideoManager"+ props.videoIdInFormDelete
                                                                : "#deleteVideoManager-default"}
                                                    >
                                                        <i className="fa fa-trash" aria-hidden="true"></i> Xóa video
                                                    </button>
                                                </li>
                                                <li>
                                                    <button 
                                                        onClick={()=>props.isShowFormUpdateVideo()}
                                                        className="btn btn-light w-100 mt-1" type="button" data-bs-toggle="modal" 
                                                        data-bs-target={
                                                            props.videoIdInFormState
                                                            ? "#publishVideoManager"+props.videoIdInFormState
                                                            : "#publishVideoManager-default"
                                                            } 
                                                        >
                                                        <i className="fas fa-pen" /> Sửa video
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                }
                            </div>
                        <div className="statisticalVideo d-flex">
                            <div className="d-flex">
                                <p>{props.view}</p>&nbsp;
                                <p>views</p>
                            </div>&nbsp;
                            <div >•</div>&nbsp;
                            <div className="d-flex">
                                <p>{props.like}</p>&nbsp;
                                <p>like</p>
                            </div>&nbsp;
                            <div >•</div>&nbsp;
                            <div className="d-flex">
                                {moment(props.date).fromNow() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
)