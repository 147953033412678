
import React, {useState} from 'react'
import { UploadVideoToS3 } from './UploadVideoToS3';

export default function ComponentUploadVideoToS3({ formState, setFormState}) {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [process, setProcess] = useState(0);
    const handleUploadVideo = async() => {
       await UploadVideoToS3(file, formState, setFormState, setIsLoading, setProcess);
      };
      const handleFileVideoSelect = (e) => {
        setFile(e.target.files[0]);
        
      }
  return (
    <div className='p-2'>
        {
                !formState.url 
                ?<div className="d-flex justify-content-between">
                    <input type="file" title='tải lên video' accept="video/*" onChange={handleFileVideoSelect} />
                    {
                        file && !process && 
                        <div className='pe-3'>
                            <button onClick={handleUploadVideo} className=''>Tải lên</button>
                        </div>
                    }
                </div>
                :
                (
                    formState.url && 
                    <div className="alert d-flex flex-column align-items-center" role="alert">
                        <video className='isVideoDownload' controls src={formState.url}></video>
                        <div>
                            Đã tải lên Video, hãy tiếp tục hoàn thiện chi tiết video
                        </div>
                    </div>
                )
        }
              { 
              isLoading && 
              <div className="progress mt-2">
                    <div className="progress-bar" role="progressbar" style={{width: process+'%'}} 
                    aria-valuenow={process} aria-valuemin={0} aria-valuemax={100}
                    >
                        {process}%
                    </div>
                </div>}
        
    </div>
  )
}
