import React from 'react'
import { useQuery } from '@apollo/client'
import {GET_AVATAR_USER_MANAGE} from '../../../graphql-client/Queries'
import { UPDATE_SUBSCRIBED } from '../../../graphql-client/notification/Notification'
import { ConvertToCloudFrontUrl } from '../../../utils/ConvertToCloudFrontUrl'
export default function TopManageUser() {
    const [modalVisible] = React.useState(false)
    
    const {loading, error, data, subscribeToMore} = useQuery(GET_AVATAR_USER_MANAGE)
    let userId = data && data.userManage && data.userManage.id
    React.useEffect(() => {
                let unsubscribe
                if (!modalVisible) {
                    unsubscribe = subscribeToMore({
                        document: UPDATE_SUBSCRIBED,
                        variables: {userId : userId},
                        updateQuery: (prev, { subscriptionData }) => {
                        if (!subscriptionData.data) return prev;
                        const newUserManage = subscriptionData.data.newUserSubscription.subscriber ;
                        return Object.assign({}, prev, {
                            userManage: newUserManage,
                        }
                    );
                }
            });
            }

            if (unsubscribe) return () => unsubscribe()
        }, [modalVisible, subscribeToMore, userId])

    
        if(loading) return <p>loading...</p>
        if(error) return 'error'
        const cover = data.userManage.cover === process.env.REACT_APP_DEFAULT_COVER
                                                ? process.env.REACT_APP_DEFAULT_COVER
                                                : ConvertToCloudFrontUrl(data.userManage.cover)
        const avatar = data.userManage.avatar === process.env.REACT_APP_DEFAULT_AVATAR
                                                  ?process.env.REACT_APP_DEFAULT_AVATAR
                                                  :ConvertToCloudFrontUrl(data.userManage.avatar)
    return (
        <div>
            <div className=" border d-flex flex-column">
                <img className="imgCover mx-auto" src={cover} alt={'ảnh bìa của người dùng '+data.userManage.username} />
                <img className="avatar mx-auto rounded-circle" src={avatar} alt={'ảnh đại diện của người dùng '+data.userManage.username} />
                <h5 className="nameUser mx-auto mt-2" >
                    {data.userManage.username}
                </h5>
                <div className="d-flex mx-auto mt-3">
                    <p className='me-1'>{data.userManage.countSubscribedTo} </p>
                    <div>•</div>
                    <p className='ms-1'> Người đăng ký</p>
                </div>
            </div>
        </div>
    )
}
