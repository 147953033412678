import React from 'react'
import HomeLeft from '../home/home_left/HomeLeft'
import { useAuthContext } from '../../context/AuthContext'
import NotificationError from '../NotificationError'
import VideoListRight from './VideoListRight'
export default function VideoList() {
  return (
    <div className='row' >
                <HomeLeft></HomeLeft>
                <VideoListRight/>
            </div>
  )
}
