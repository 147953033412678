import { useQuery } from '@apollo/client'
import React from 'react'
import { getIntroduceVideo } from '../../../../../../graphql-client/Queries'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ConvertToCloudFrontUrl } from '../../../../../../utils/ConvertToCloudFrontUrl';
export default React.memo(function IntroduceVideoPlaying() {
    let {id} = useParams();
    
    const {loading, error, data}= useQuery(getIntroduceVideo,{
        variables: {id}
    });
    if(loading) return <p>loading...</p>;
    if (error) return <p>Error! {error.message}</p>;
    
    return (    
        <div className=" tab-pane show active" id="introduce" role="tabpanel" aria-labelledby="introduce-tab-introduce">
            <div className="card card-body">
                <div className="d-flex flex-row nameUserWatching m-1">
                    <Link 
                        to={"/user/" + data.video.user.id}
                    >
                        <img 
                            className="img-video_avatar rounded-circle" alt={'ảnh đại diện của người dùng '+data.video.user.username } 
                            src={ConvertToCloudFrontUrl(data.video.user.avatar)} 
                        />
                    </Link>
                    <Link 
                        to={"/user/" + data.video.user.id}
                        className="nameUser"
                    >
                        <p className='ms-2 mt-1'>
                            {data.video.user.username}
                        </p>
                    </Link> 
                </div>
                <h5 className='m-1 p-1'>
                    Mô tả video: 
                </h5>
                <div className=' m-1 p-1'>
                    {
                        data.video.description 
                        ?
                        <p className="text_per_line mb-0">
                            {data.video.description}
                        </p>
                        :
                        <div className="alert alert-dark" role="alert">
                            <div className="text_per_line">
                                Video chưa có mô tả nào.
                            </div>
                        </div>
                    }
                </div>
            </div>
            
        </div>
            )
}
)