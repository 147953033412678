import React from 'react'
import NewComment from './commentFormVideo/NewComment'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../../../../../../context/AuthContext'
import { COMMENTS_VIDEO } from '../../../../../../../graphql-client/Queries'
import { UPDATE_COMMENT } from '../../../../../../../graphql-client/notification/Notification'
import moment from 'moment'
import { ConvertToCloudFrontUrl } from '../../../../../../../utils/ConvertToCloudFrontUrl'
export default React.memo(function CommentsVideo() {
    let {id} = useParams();
    const [modalVisible] = React.useState(false)
    
    const {loading, error, data,fetchMore, subscribeToMore} = useQuery(COMMENTS_VIDEO,{
        variables: {videoId: id, take: 10},
    });
    
    React.useEffect(() => {
        let unsubscribe
        if (!modalVisible) {
                unsubscribe = subscribeToMore({
                    document: UPDATE_COMMENT,
                    variables: {videoId : id},
                    updateQuery: (prev, { subscriptionData }) => {
                        if (!subscriptionData.data) return prev;

                        const newComment = subscriptionData.data.newComment.comment;
                        const parentCommentId = newComment.parentCommentId;

                        // If new comment is not a child comment, add it to the existing comments array
                        if (!parentCommentId) {
                        const updatedComments = [newComment, ...prev.commentsVideo];
                        return {
                            ...prev,
                            commentsVideo: updatedComments
                        };
                        }

                        // If new comment is a child comment, find the parent comment and add the new comment to its childrenComments array
                        const updatedComments = prev.commentsVideo.map(comment => {
                        if (comment.id === parentCommentId) {
                            return {
                            ...comment,
                            childrenComments: [...comment.childrenComments, newComment]
                            };
                        } else {
                            return comment;
                        }
                        });

                        return {
                        ...prev,
                        commentsVideo: updatedComments
                        };
                    }
                   
                });
            }

        if (unsubscribe) return () => unsubscribe()
    }, [modalVisible, subscribeToMore, id])

    const {isAuthenticated} = useAuthContext()

    const onLoadMore = () => fetchMore({
        variables: {
            videoId: id,
            take:data.commentsVideo.length+5
        },
    })
    const handleScroll = ({ currentTarget }, onLoadMore) => {
        if (
          currentTarget.scrollTop + currentTarget.clientHeight >=
          currentTarget.scrollHeight
        ) {
          onLoadMore();
        }
      };

      if(loading) return <p>loading...</p>;
      if (error) return <p>Error! {error.message}</p>;
      const listParentCommentVideo = data.commentsVideo
    return(
        <div className="card  commentsVideo" onScroll={e => handleScroll(e, onLoadMore)}>
            <div className="card-body p-4 m-1 ">
                <div className="row  ">
                    <div className="col " >
        {   listParentCommentVideo &&
            listParentCommentVideo.map((comment)=>
                {
                    return (
                        <div key={comment.id}>
                            <div className="d-flex flex-start" >
                                <Link to={"/user/"+ comment.user.id}>
                                    <img className="rounded-circle shadow-1-strong me-3 avatarComment"
                                    src={ConvertToCloudFrontUrl(comment.user.avatar)} alt={'ảnh đại diện của người dùng '+comment.user.username}  />
                                </Link>
                                <div className="flex-grow-1 flex-shrink-1">
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className='d-flex flex-row'>
                                                <Link className='nameUser' to={"/user/"+ comment.user.id}>
                                                    <p className="mb-1 text-body ">
                                                        {comment.user.username} 
                                                    </p>
                                                </Link>
                                                <p className='ms-1'> - {moment(comment.createdAt).fromNow()}</p>
                                            </div>
                                            <div className='d-flex flex-row '>
                                                <span className="btn d-flex"  data-bs-toggle="collapse" href={'#buttonRepNewComment' + comment.id} 
                                                        aria-expanded="false" aria-controls={'buttonRepNewComment' + comment.id}
                                                >
                                                    <p className='mb-1'>Trả lời <i className="fas fa-reply fa-xs"></i></p> 
                                                </span>
                                            </div>
                                        </div>
                                        <p className="small mb-0">
                                            {comment.text}
                                        </p>
                                        <div className="collapse" id={'buttonRepNewComment' + comment.id}>
                                            {!isAuthenticated ? 
                                                <div className="alert alert-dark" role="alert">
                                                    Hãy đăng nhập hoặc đăng ký để có thể tham gia thảo luận
                                                </div> :
                                                <NewComment  parentCommentId={comment.id}
                                                ></NewComment>
                                            }
                                        </div>
                                    </div>
                                    {
                                        comment.childrenComments.length !== 0
                                        && 
                                        <button 
                                            className="btn ml-auto"  
                                            data-bs-toggle="collapse" 
                                            data-bs-target={'#buttonListComment' + comment.id} 
                                            aria-expanded="false"  aria-controls="collapseExample"
                                        >
                                            Xem 
                                            {
                                            comment.childrenComments.length !==0 
                                            && " "+ comment.childrenComments.length
                                            } câu trả lời ...
                                        </button>
                                    }
                                    <div className="collapse" id={'buttonListComment' + comment.id} >
                                        {
                                            comment.childrenComments.slice().reverse().map((comment)=>{
                                                return(
                                                    <div key={comment.id}>
                                                        <div className="d-flex flex-start mt-4">
                                                            <Link className="me-3" to={"/user/"+ comment.user.id}>
                                                                <img className="rounded-circle shadow-1-strong avatarComment"
                                                                src={ConvertToCloudFrontUrl(comment.user.avatar)} alt={'ảnh đại diện của người dùng '+comment.user.username}
                                                                    />
                                                            </Link>
                                                            <div className="flex-grow-1 flex-shrink-1">
                                                                <div>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div className='d-flex flex-row'>
                                                                            <Link className='nameUser' to={"/user/"+ comment.user.id}>
                                                                                <p className="mb-1 text-body ">
                                                                                    {comment.user.username} 
                                                                                </p>
                                                                            </Link>
                                                                            <p>&nbsp; - {moment(comment.createdAt).fromNow()}</p>
                                                                        </div>

                                                                    </div>
                                                                    <p className="small mb-0">
                                                                        {comment.text}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )
                }
            )
        }
                    </div>
                </div>
            </div>
        </div>
    )
}
)