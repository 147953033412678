import React, {useState} from 'react'
import { ConvertToCloudFrontUrl } from '../../utils/ConvertToCloudFrontUrl'
import VideoCard from '../home/home_right/videoCard/VideoCard';
import { SHOW_VIDEO_LIST } from '../../graphql-client/Queries';
import { useQuery, useApolloClient } from '@apollo/client';
import { useParams } from "react-router-dom";
export default function VideoListRight(props) {
    let id = useParams()
    
     const [upTakeOnFetchMore,setUpTakeOnFetchMore] = useState(5);
            const [beginTake,setBeginTake] = useState(5);
            const [isShowClickMoreVideo, setIsShowClickMoreVideo] =useState(
              4
            )
            const [buttonStatus, setButtonStatus] = useState({
              isCountVideoDoClick: beginTake,
              showButtonNewVideo: false,
              
          })
          console.log(props.idVideoList);
          
    const {data, loading, error, fetchMore, refetch} = useQuery(SHOW_VIDEO_LIST, {
          variables:{
            idVideoList: id.idListVideo,
            take: beginTake
          },
           fetchPolicy: "network-only",
        })
        if(loading) return (
            <div className="card" aria-hidden="true">
            <img src="..." className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title placeholder-glow">
                <span className="placeholder col-6" />
              </h5>
              <p className="card-text placeholder-glow">
                <span className="placeholder col-7" />
                <span className="placeholder col-4" />
                <span className="placeholder col-4" />
                <span className="placeholder col-6" />
                <span className="placeholder col-8" />
              </p>
            </div>
          </div>
            )
          if(error) return console.error();
      
          const onLoadMore = () => {
            setIsShowClickMoreVideo(isShowClickMoreVideo+4)
            
            fetchMore({
            variables: {
                idVideoList: props.idVideoList,
                take: isShowClickMoreVideo+upTakeOnFetchMore
                // take: data.showVideoList.length+upTakeOnFetchMore
            },
          })}
          const showVideo = data && data.showVideoList 
  return (
     <div className="col-xxl-10 col-xl-10 col-lg-10 col-mb-10 col-sm-12 videosRecommended " 
            >
                <div className="p-0 marginVideoRecommend" >
                    <h5 className='ms-3 mt-1'>danh sách video khóa học</h5>
                    {/* {
                    data.videosRecommended.length===0
                    ?<NotificationError text={'Chưa có video nào.'}></NotificationError>
                    :<div className="row ms-auto">
                        {           
                            data.videosRecommended.map((video) =>
                                {  
                                    return (                                  
                                        <VideoCard
                                            key={video.id}
                                            title ={video.title}
                                            username={video.user.username}
                                            date={video.createdAt}
                                            view={video.countView}
                                            like={video.countLike}  
                                            videoId={video.id}
                                            avatarUser={video.user.avatar}
                                            idUser={video.user.id}
                                            thumbnail={video.thumbnail}
                                            tags={video.tags}
                                        >
                                        </VideoCard>
                                    )
                                }
                            )
                        }
                    </div>
                    } */}
                        {
                        <div className="card" aria-hidden="true">
                            <img src="..." className="card-img-top" alt="..." />
                            <div className="card-body">
                            <h5 className="card-title placeholder-glow">
                                <span className="placeholder col-6" /> xin chào
                            </h5>
                            <p className="card-text placeholder-glow">
                                <span className="placeholder col-7" />
                                <span className="placeholder col-4" />
                                <span className="placeholder col-4" />
                                <span className="placeholder col-6" />
                                <span className="placeholder col-8" />
                            </p>
                            </div>
                        </div>
                        }
                </div>
                <div className="row g-0">
                                                                <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12">
                                                                    <img
                                                                    className="imgListVideo m-2" 
                                                                     alt={'ảnh đại diện khóa học '+props.title}
                                                                     src={
                                                                        props.thumbnail?
                                                                        ConvertToCloudFrontUrl(props.thumbnail)
                                                                        :'/default-image.jpg'
                                                                     }
                                                                    />
                                                                </div>
                                                                <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title text_per_line">
                                                                            {props.title}
                                                                        </h5>
                                                                        
                                                                        <p className="card-text text_per_line">
                                                                            {props.about}
                                                                        </p>
                                                                        
                                                                        <div className="statisticalVideo d-flex">
                                                                            <div className="d-flex">
                                                                                <p>
                                                                                    {props.countVideo}
                                                                                </p>
                                                                                &nbsp;
                                                                                <p>video</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                 { showVideo.map((video)=>{
                                                                                   
                                                                                  return(
                                                                                    <VideoCard
                                                                                        key={video.id}
                                                                                        publishVideoManager={false}
                                                                                        document={video.document}
                                                                                        description={video.description}
                                                                                        title ={video.title}
                                                                                        date={video.createdAt}
                                                                                        view={video.countView}
                                                                                        like={video.countLike}
                                                                                        videoId={video.id}
                                                                                        tags={video.tags}
                                                                                        thumbnail={video.thumbnail}
                                                                                    >
                                                                                    </VideoCard>
                                                                                  )
                                                                                })}
                                                                                <button className=' btn  ms-2' 
                                                                            onClick={()=>{
                                                                                setButtonStatus({isCountVideoDoClick: buttonStatus.isCountVideoDoClick+upTakeOnFetchMore})// cộng :5 băng với số dữ liệu được lấy thêm 
                                                                                onLoadMore()
                                                                            }}
                                                                            > 
                                                                            <b>
                                                                                Xem thêm video
                                                                            </b>
                                                                            </button>
                                                                </div>
            </div>
  )
}
